import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="analytics"
export default class extends Controller {
  initialize() {
    if (this.enableOutboundTracking()) {
      this.trackLinks()
    }
  }

  // Mixpanel event tracking
  async trackEvent(event) {
    // Extracts the event type (e.g. playing or paused)
    const type = event.type.split(":")[1] || event.type
    let param =
      this.data.get(`track-event-${type}-param`) ||
      this.data.get("track-event-param") ||
      this.data.get("track-event-outbound-param")

    if (!param) return

    const { event_type, content_discovery_context, outbound_link_context } =
      JSON.parse(param)
    const href = this.getHrefFromEvent(event)
    const params = this.buildEventParams(
      event_type,
      content_discovery_context,
      outbound_link_context,
      href,
    )

    await post("/tracks", {
      body: { track: params },
    })
  }

  getHrefFromEvent(event) {
    let target = event.target
    while (target && target.tagName !== "A") {
      target = target.parentElement
    }
    return target ? target.href : null
  }

  buildEventParams(
    event_type,
    content_discovery_context,
    outbound_link_context,
    href,
  ) {
    const params = {
      event_type: event_type || "outbound_link_click",
      event_properties: this.buildEventProperties(
        content_discovery_context,
        outbound_link_context,
        href,
      ),
    }
    return params
  }

  buildEventProperties(content_discovery_context, outbound_link_context, href) {
    const event_properties = {}

    const metaNames = [
      "mp_$device_id",
      "mp_$distinct_id",
      "mp_$current_url",
      "mp_ip",
      "mp_params",
    ]

    metaNames.forEach(name => {
      const metaTag = document.querySelector(`meta[name="${name}"]`)
      if (metaTag) {
        event_properties[name.split("mp_")[1]] = metaTag.getAttribute("content")
      }
    })

    if (content_discovery_context) {
      event_properties["content_discovery_context"] = content_discovery_context
    }

    if (outbound_link_context && href) {
      event_properties["outbound_link_context"] = outbound_link_context
      event_properties["outbound_url"] = href
    }

    return event_properties
  }

  enableOutboundTracking() {
    return this.data.get("track-event-outbound-param")
  }

  trackOutbound(event) {
    this.trackEvent(event)
  }

  trackLinks() {
    this.outboundLinks.forEach(link => {
      link.addEventListener("click", event => this.trackOutbound(event))
    })
  }

  get outboundLinks() {
    return this.element.querySelectorAll('a[href^="http"]')
  }
}
