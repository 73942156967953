import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import ContentLoader from "stimulus-content-loader"
import NestedForm from "stimulus-rails-nested-form"
import Reveal from "stimulus-reveal-controller"

const application = Application.start()
application.register("content-loader", ContentLoader)
application.register("nested-form", NestedForm)
application.register("reveal", Reveal)

// A custom :open action for whenever the details element is toggled open.
// Usage in HTML: <details data-action="toggle->someController#method:open">
application.registerActionOption("open", ({ event }) => {
  if (event.type == "toggle") {
    return event.target.open == true
  } else {
    return true
  }
})

// Configure Stimulus development experience
// application.warnings = true
// application.debug = process.env.NODE_ENV === "development"
window.Stimulus = application

// Automatically load controllers from ViewComponents directory.
const context = require.context(
  "../../components/",
  true,
  /(.*)\/.+_controller\.js$/,
)

Stimulus.load(definitionsFromContext(context))

export { application }

document.addEventListener("turbo:before-frame-render", async event => {
  event.preventDefault()

  const entranceTargets =
    event.detail.newFrame.querySelectorAll("[data-animate-in]")
  if (entranceTargets.length) {
    entranceTargets.forEach(element => {
      if (typeof element.dataset.animateIn === "string") {
        element.classList.add(element.dataset.animateIn)
      }
    })
  }

  // Frame rendering will pause and wait for all exit animations to complete.
  const exitTargets = event.target.querySelectorAll("[data-animate-out]")
  if (exitTargets.length) {
    await Promise.all(
      [...exitTargets].map(
        element =>
          new Promise(resolve => {
            if (typeof element.dataset.animateIn === "string") {
              element.addEventListener("animationend", resolve)
              element.classList.add(element.dataset.animateOut)
            } else {
              resolve()
            }
          }),
      ),
    )
  }

  event.detail.resume()
})

document.addEventListener("turbo:before-stream-render", event => {
  if (event.target.action === "remove") {
    const targetFrame = document.getElementById(event.target.target)
    if (targetFrame?.dataset.fadeOut) {
      event.preventDefault()
      const elementBeingAnimated = targetFrame
      elementBeingAnimated.classList.add("animate-fade-out")
      elementBeingAnimated.addEventListener("animationend", () => {
        targetFrame.remove()
      })
    }
  }
})
