import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ui--tabs"
export default class extends Controller {
  static get targets() {
    return ["tab", "panel"]
  }

  static values = {
    panelActiveClass: String,
    panelInactiveClass: String,
    tabActiveClass: String,
    tabInactiveClass: String,
  }

  initialize() {
    this.updatePanel = this.updatePanel.bind(this)
    this.updateTab = this.updateTab.bind(this)
  }

  connect() {
    const selected = this.element.querySelector('[data-active="true"]')

    this.shouldFocus = false
    this.updateActive(selected || this.tabTargets.at(0))

    this.shouldFocus = true
  }

  updateActive(tab) {
    this.activePanelId = tab.dataset["ui-TabsPanelIdParam"]
    this.activeTabId = tab.id
    this.update()
  }

  navigate(event) {
    const index = this.tabTargets.indexOf(event.currentTarget)
    const nextTab = {
      ArrowDown: this.tabTargets[index + 1] || this.tabTargets[0],
      ArrowUp: this.tabTargets[index - 1] || this.tabTargets.at(-1),
      ArrowLeft: this.tabTargets[index - 1] || this.tabTargets.at(-1),
      ArrowRight: this.tabTargets[index + 1] || this.tabTargets[0],
      Home: this.tabTargets[0],
      End: this.tabTargets.at(-1),
    }

    if (event.code in nextTab) {
      this.updateActive(nextTab[event.code])
    }
  }

  showPanel(event) {
    this.activePanelId = event.params.panelId
    this.activeTabId = event.currentTarget.id
    this.dispatch("change-active", { detail: { panelId: this.activePanelId } })
    this.update()
  }

  update() {
    this.tabTargets.forEach(this.updateTab)
    this.panelTargets.forEach(this.updatePanel)
  }

  updatePanel(el) {
    const selected = el.id === this.activePanelId
    const classLists = {
      active: this.panelActiveClassValue,
      inactive: this.panelInactiveClassValue,
    }

    if (selected) {
      el.removeAttribute("hidden")
    } else {
      el.setAttribute("hidden", "hidden")
    }

    this.updateTarget({ el, classLists, selected })
  }

  updateTab(el) {
    const selected = el.id === this.activeTabId
    const classLists = {
      active: this.tabActiveClassValue,
      inactive: this.tabInactiveClassValue,
    }

    el.setAttribute("aria-selected", selected ? "true" : "false")
    el.setAttribute("tabindex", selected ? "0" : "-1")

    if (selected && this.shouldFocus) {
      el.focus()
    }

    this.updateTarget({ el, classLists, selected })
  }

  updateTarget({ el, classLists, selected }) {
    const { active, inactive } = classLists

    this.toggleClasses({ el, classList: active, force: selected })
    this.toggleClasses({ el, classList: inactive, force: !selected })
  }

  toggleClasses({ el, classList, force }) {
    if (!el || !classList) {
      return
    }

    classList.split(" ").forEach(className => {
      el.classList.toggle(className, force)
    })
  }
}
