/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).on("turbo:load", function () {
  if ($("[data-color-match]").length < 1) {
    return
  }
  const $window = $(window)

  const isColorInRange = function (base, sample) {
    const THRESHOLD = 40
    let i = 0
    while (i < 3) {
      if (base[i] - THRESHOLD > sample[i] || base[i] + THRESHOLD < sample[i]) {
        return false
      }
      i++
    }
    return true
  }

  const sampleCanvasColor = function (video, c, bg, guide) {
    const ctx = c.getContext("2d")
    ctx.drawImage(video, 0, 0)
    const p = ctx.getImageData(0, 0, 1, 1).data
    if (isColorInRange(guide, p)) {
      bg.style.backgroundColor = `rgb(${p[0]},${p[1]},${p[2]})`
    }
  }

  var drawingLoop = function (v, c) {
    if (!v.paused) {
      window.requestAnimationFrame(() => drawingLoop(v, c))
    }

    c.height = v.clientHeight
    c.width = v.clientWidth

    const ctx = c.getContext("2d")
    ctx.drawImage(
      v,
      0,
      0,
      v.videoWidth,
      v.videoHeight,
      0,
      0,
      v.offsetWidth,
      v.offsetHeight
    )
  }

  const needsCanvas = function (video) {
    const videoId = $(video).attr("id")
    return $(`canvas[data-video='${videoId}']`).length < 1
  }

  const getCanvas = function (video) {
    const videoId = $(video).attr("id")
    return $(`canvas[data-video='${videoId}']`)
  }

  const createCanvas = function (video) {
    if (!needsCanvas(video)) {
      return getCanvas(video)
    }

    const $video = $(video)
    return $("<canvas />")
      .addClass($video.data("canvas-classes"))
      .attr("data-video", $video.attr("id"))
      .insertBefore($video)
  }

  const pauseOnResize = function (video, resizeTimer) {
    $window.on("resize.colorSwatch", function (e) {
      if (!video.paused) {
        video.pause()
      }
      clearTimeout(resizeTimer)
      return (resizeTimer = setTimeout(function () {
        if (video.paused) {
          video.play()
        }
      }, 250))
    })
  }

  const videoColorMatch = function (i, bg) {
    const resizeTimer = undefined
    const video = document.getElementById($(bg).data("color-match"))
    const guide = $(bg).data("color-guide")
    let canvas = false
    if (!video) {
      return
    }

    video.addEventListener(
      "canplaythrough",
      () => {
        canvas = createCanvas(video).get(0)
        sampleCanvasColor(video, canvas, bg, guide)
        pauseOnResize(video, resizeTimer)
        setTimeout(function () {
          canvas.width = 720
          canvas.height = 720
          video.pause()
          video.play()
        }, 25)
        $(video).addClass("tdd--canvas-loaded")
      },
      { once: true }
    )

    video.addEventListener(
      "play",
      () => {
        if (canvas) {
          return drawingLoop(video, canvas)
        }
      },
      false
    )

    video.pause()
    return video.play()
  }

  const colorMatchVideos = function () {
    $("[data-color-match]").each(videoColorMatch)
  }

  return $(document).on("ready", colorMatchVideos)
})
