import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="color-picker"
export default class extends Controller {
  static targets = ["color", "hex", "preview"]

  connect() {
    this.color = this.colorTarget.value
    this.onColorChanged()
  }

  isValidColor(color) {
    return /(^#[0-9A-F]{6}$)/i.test(color)
  }

  updateColor(event) {
    const color = event.target?.value
    if (!this.isValidColor(color)) {
      return
    }
    this.color = color
    this.onColorChanged()
  }

  onColorChanged() {
    if (this.hasColorTarget && this.colorTarget.value !== this.color) {
      this.colorTarget.value = this.color
    }

    if (this.hasHexTarget && this.hexTarget.value !== this.color) {
      this.hexTarget.value = this.color
    }

    if (this.hasPreviewTarget) {
      this.previewTarget.style.backgroundColor = this.color
    }
  }
}
