document.addEventListener("turbo:load", function () {
  const lazyVideos = [].slice.call(
    document.querySelectorAll("video[data-lazy]")
  )
  const loadVideo = function (video) {
    for (let source in video.children) {
      var videoSource
      if (video.children) {
        videoSource = video.children[source]
      }
      if (
        typeof videoSource.tagName === "string" &&
        videoSource.tagName === "SOURCE"
      ) {
        videoSource.src = videoSource.dataset.src
        video.load()
      }
      return
    }
  }

  if ("IntersectionObserver" in window) {
    var lazyVideoObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          loadVideo(video.target)
          lazyVideoObserver.unobserve(video.target)
        }
      })
    })
    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo)
    })
  } else {
    lazyVideos.forEach(function (lazyVideo) {
      loadVideo(lazyVideo)
    })
  }
})
