import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cloudinary"
export default class extends Controller {
  static targets = ["input", "preview"]

  static values = {
    // See https://cloudinary.com/documentation/upload_widget_reference#parameters
    options: { type: Object, default: {} },

    // This is a little hacky, but it allows for an easy external preview target
    previewSelector: { type: String, default: "#cloudinary-video-preview" },
  }

  connect() {
    this.createUploadWidet()

    this.onSucces = this.success.bind(this)
  }

  async createUploadWidet() {
    await this.injectScripts()

    this.widget = cloudinary.createUploadWidget(
      this.optionsValue,
      (error, result) => {
        if (!error && result?.event === "success") {
          this.onSucces(result)
        }
      },
    )
  }

  open() {
    this.widget?.open()
  }

  updatePreview(src) {
    const preview = this.hasPreviewTarget
      ? this.previewTarget
      : this.externalPreview

    if (!src || !preview) {
      return
    }

    preview.innerHTML = `<video><source src="${src}" type="video/mp4"></video>`
    const video = preview.querySelector("video")

    video?.load()
    video?.play()
  }

  get externalPreview() {
    return this.hasPreviewSelectorValue
      ? document.querySelector(this.previewSelectorValue)
      : undefined
  }

  updateValue(value) {
    this.inputTarget.value = value
  }

  success(result) {
    this.updatePreview(result.info.secure_url)

    this.inputTarget.value = result.info.path

    this.dispatch("uploaded", {
      detail: { result },
    })
  }

  injectScripts() {
    return new Promise((resolve, reject) => {
      if (typeof window.cloudinary !== "undefined") {
        resolve()
      } else {
        const script = document.createElement("script")
        script.src = "https://upload-widget.cloudinary.com/global/all.js"
        script.type = "text/javascript"
        script.onload = () => resolve()
        script.onerror = error =>
          reject(`Failed to load Cloudinary upload widget: ${error}`)

        document.body.appendChild(script)
      }
    })
  }
}
