/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).on("turbo:load", function () {
  const bindVideos = function (idx, ctrl) {
    const video = document.getElementById($(ctrl).data("video-ctrl"))
    if (video.length < 1) {
      return
    }

    video.addEventListener(
      "play",
      () => {
        $(ctrl).find(".js-play").addClass("hidden")
        $(ctrl).find(".js-pause").removeClass("hidden")
      },
      false
    )

    video.addEventListener(
      "pause",
      () => {
        $(ctrl).find(".js-play").removeClass("hidden")
        $(ctrl).find(".js-pause").addClass("hidden")
      },
      false
    )

    $(ctrl).on("click", () => {
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    })
  }

  const toggleAllVideos = function (e) {
    const action = $(e.currentTarget).data("all-videos-ctrl")
    const videos = document.querySelectorAll("video")
    const $toggle = $(e.currentTarget)
    const pause = action === "pause"

    const toggleAction = pause ? "play" : "pause"
    $toggle
      .data("all-videos-ctrl", toggleAction)
      .attr("data-all-videos-ctrl", toggleAction)

    const show = pause ? "play" : "pause"
    const hide = pause ? "pause" : "play"
    $toggle.find(`[data-text='${show}']`).show()
    $toggle.find(`[data-text='${hide}']`).hide()

    return videos.forEach(video => {
      if (action === "pause") {
        video.autoplay = false
        return video.pause()
      } else {
        video.autoplay = true
        return video.play()
      }
    })
  }

  const bindControls = function () {
    $("[data-video-ctrl]").each(bindVideos)
    $(document).on("click", "[data-all-videos-ctrl]", toggleAllVideos)
  }

  return $(document).on("ready", bindControls)
})
