/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const BetterModals = {
  init() {
    this.bindLinks()
    this.bindCancelButton()
    return this.bindAlternateCloseEvents()
  },

  // Bind any links with 'data-better-modal-link=true'
  bindLinks() {
    $(document).on("click", "[data-better-modal-link=true]", event => {
      const modalLink = $(event.target).closest("[data-better-modal-link=true]")
      const targetModal = modalLink.data("modal")
      this.reloadIframes($(targetModal))
      if (modalLink.data("better-modal-ajax")) {
        $(targetModal).data("modal-ajax", "true")
      }
      if (modalLink.data("better-modal-reload")) {
        $(targetModal).data("modal-reload", "true")
      }

      this.showModal($(targetModal))
      return event.preventDefault()
    })

    return $(document).on("click", ".close-modal", event => {
      const target = $(event.target).data("target")
      this.closeModal($(target))
      return $(document).off("keyup.closeBetterModal")
    })
  },

  bindCloseButton() {
    return $(".better-modal-close-icon").on("click", event => {
      const target = $(event.target).data("target")
      this.closeModal($(target))
      return $(target + ".show-video-modal iframe").attr("src", "")
    })
  },

  // Dynamically bind any 'cancel' buttons so we don't have to write more JS
  // elsewhere...
  bindCancelButton() {
    return $(document).on("click", "[data-better-modal-cancel=true]", event => {
      event.preventDefault()
      return this.closeModal($(event.currentTarget).closest(".better-modal"))
    })
  },

  // Dynamically add a 'close' button so we don't have to hard code it
  addCloseButton(modal) {
    if (modal.data("close-button-added") === undefined) {
      modal
        .find('.better-modal-contents, [data-modal="content"]')
        .prepend(
          '<button data-target="#' +
            modal.attr("id") +
            '" class="better-modal-close-icon"><span class="sr-only">close</span></button>'
        )
      modal.data("close-button-added", "true")
      return this.bindCloseButton()
    }
  },

  // Add paging buttons if ajax modal
  addPagingButtons(modal) {
    if (
      modal.data("modal-ajax") === "true" &&
      modal.data("paging-buttons-added") !== "true"
    ) {
      const next = `<button data-target="javascript:;" class="better-modal-pager-next \
icon-chevron-right"><span class="sr-only">Next</span></button>`
      const prev = `<button data-target="javascript:;" class="better-modal-pager-prev \
icon-chevron-left"><span class="sr-only">Prev</span></button>`
      modal.find(".better-modal-contents").prepend(next + prev)
      return modal.data("paging-buttons-added", "true")
    }
  },
  // @bindPagingButtons()

  // Open the modal, give it a nice 'top' value, and add the close button
  // The 'modal' parameter should be a jquery object.
  showModal(modal) {
    if (!modal.length) {
      return
    }
    this.addCloseButton(modal)
    this.addPagingButtons(modal)
    $("body").append(modal)
    $("body").addClass("modal-open")
    return modal.addClass("better-modal-visible")
  },

  closeModal(modal) {
    $("body")
      .removeClass("modal-open")
      .trigger("App::BetterModals::close", [modal])
    modal.removeClass("better-modal-visible")
    // Ajax content is overwritten on each load, so mark close button as not
    // added, so that it can reloaded on next modal load.
    if (
      modal.data("modal-ajax") === "true" ||
      modal.data("modal-reload") === "true"
    ) {
      modal.data("close-button-added", "false")
    }
    if (
      modal.data("modal-ajax") === "true" ||
      modal.data("modal-reload") === "true"
    ) {
      modal.data("paging-buttons-added", "false")
    }
    return this.removeIframes(modal)
  },

  removeIframes(modal) {
    const $iframes = modal.find("iframe")
    return $iframes.each((idx, iframe) =>
      $(iframe).data("src", $(iframe).attr("src")).attr("src", "")
    )
  },

  reloadIframes(modal) {
    const $iframes = modal.find("iframe")
    return $iframes.each(function (idx, iframe) {
      if (!$(iframe).attr("src") && $(iframe).data("src")) {
        return $(iframe).attr("src", $(iframe).data("src"))
      }
    })
  },

  // These alternate 'close' events can be attached to things like Submit buttons
  // with a data attribute. This way, we don't have to manually set up these
  // handlers in a separate JS file
  bindAlternateCloseEvents() {
    $(document).on("click", "[data-better-modal-close=true]", event => {
      return this.closeModal($(event.currentTarget).closest(".better-modal"))
    })

    return $(document).on("keyup.closeBetterModal", e => {
      const target = $(".better-modal-close-icon").data("target")
      if (e.key === "Escape" && this.closeModal($(target))) {
        return e.stopPropagation()
      }
    })
  },
}

$(document).on("turbo:load", () => BetterModals.init())

global.BetterModals = BetterModals

export default BetterModals
