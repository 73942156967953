import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ui--video-toggle"
export default class extends Controller {
  static get targets() {
    return ["playIcon", "pauseIcon"]
  }

  connect() {
    this.videoId = this.element.dataset.videoId
    this.bindVideo()
  }

  bindVideo() {
    this.video.addEventListener(
      "play",
      () => {
        this.playIconTarget.classList.add("hidden")
        this.playIconTarget.classList.remove("block")

        this.pauseIconTarget.classList.add("block")
        this.pauseIconTarget.classList.remove("hidden")
      },
      false
    )

    this.video.addEventListener(
      "pause",
      () => {
        this.playIconTarget.classList.add("block")
        this.playIconTarget.classList.remove("hidden")

        this.pauseIconTarget.classList.add("hidden")
        this.pauseIconTarget.classList.remove("block")
      },
      false
    )
  }

  toggle() {
    if (this.video.paused) {
      this.video.play()
    } else {
      this.video.pause()
    }
  }

  get video() {
    return document.getElementById(this.videoId)
  }
}
